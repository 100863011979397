.App {
  text-align: center;
  font-family: 'Trade Gothic LT W01 Bd CnNo-20', helvetica, arial, sans-serif;
}

.app-body {
  background: #fff !important;
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family:'Trade Gothic LT W01 Bd CnNo-20', helvetica, arial, sans-serif;;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #f7f2e8;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

ul {
  list-style-type: none;
  padding: 0;
}

.menu ul {
  background-color: #222;
  margin: 0;
}

.menu li {
  font-family: sans-serif;
  font-size: 1.2em;
  line-height: 40px;
  height: 40px;
  border-bottom: 1px solid #888;
}

.menu a {
  text-decoration: none;
  color: #fff;
  display: block;
}

.content {
  width: auto;
  margin: 10pt;
  display: block;
  margin-top: 0px !important;
  padding: 20px !important;
  font-family: 'Trade Gothic LT W01 Bd CnNo-20', helvetica, arial, sans-serif;
}

@media screen and (max-width: 375px) {
  .content {
    width: 90%;
    padding: 15px;
    margin: auto;
    margin-top: 15px !important;
    display: block;
    padding-bottom: 20px !important;
  }
}

.sticky {
  top: 0;
  width: 100%;
  position: fixed;
}

.content-space {
  margin-top: 100px;
  width: 100%;
  position: initial;
  margin-bottom: 10px;
}

@media screen and (max-width: 375px) {
  .content-space {
    margin-top: 90px;
    margin-bottom: 10px;
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 414px) {
  .content-space {
    margin-top: 90px;
    margin-bottom: 10px;
    padding-bottom: 20px;
  }
}

.nav-links-align {
  margin-left: auto !important;
}

p {
  text-align: left;
  padding: 2px;
}

.form-group-space {
  margin-top: 10pt;
  text-align: justify;
}

@font-face {
  font-family: dnrGlyphs;
  src: url(https://az875350.vo.msecnd.net/content/fonts/dnrGlyphs-2021102601.woff2)
      format('woff2'),
    url(https://az875350.vo.msecnd.net/content/fonts/dnrGlyphs-2021102601.woff)
      format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: cigGlyphs;
  src: url(https://az875350.vo.msecnd.net/content/fonts/cigGlyphs-20220615.woff2)
      format('woff2'),
    url(https://az875350.vo.msecnd.net/content/fonts/cigGlyphs-20220615.woff)
      format('woff');
  font-weight: normal;
  font-style: normal;
}

span.glf {
  font-weight: 400;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  font-family: dnrGlyphs;
  font-size: 9rem;
  margin: 0px !important;
  text-align: center;
}

span.glf.glf-logo_outback:before {
  content: '\E001';
  line-height: 0.37615em;
  color: #4a4a4a;
  display: flex;
  margin: 0 auto;
  text-align: center;
}

span.glf.glf-logo_bonefish:before {
  content: '\E003';
  line-height: .68em;
  color: #4a4a4a;
  display: flex;
  margin: 0 auto;
  text-align: center;
}

span.glf.glf-logo_carrabbas:before {
  display: flex;
  margin: 0 auto;
  text-align: center;
  content: '\E005';
  line-height: .28065em;
  color: #4a4a4a;
  font-size: 210px;
  font-family: cigGlyphs;
}

.brand-logo {
  width: 80px;
  padding: 5px;
  text-align: center;
  display: block;
  margin: 0 auto;
  width: auto;
  margin-bottom: 10px;
}

