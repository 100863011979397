.uppercase {
    text-transform: uppercase;
    font-weight: bold;
    text-align: start;
}

.no-spot {
    color: #931a1a;
    font-size: medium;
    font-weight: bold;
}

.hang-tight-label {
    text-align: start;
    font-weight: bold;
}

.order-id-label {
    font-weight: normal !important;
}

.title {
    padding: 5px;
    text-align: center !important;
    display: block;
    margin: 0 auto;
}

.description {
    text-align: center;
    width: 300px;
    display: block;
    margin: 0 auto;
    font-weight: bold;
}

.background {
  background: #fff !important;
}

.bfg-background {
  background: #fff !important;
}

.obs-background {
  background: #f7f2e8 !important;
}

.cig-background {
  background: #fff !important;
}