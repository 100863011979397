.trip-ctrl-btn {
  display: flex;
  margin: auto;
  padding: 10px;
}

.btn-room {
  padding: 5px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 5px;
}

.uppercase {
  text-transform: uppercase;
  font-weight: bold;
  font-family: introRust, sans-serif;
}

.no-spot {
  color: #931a1a;
  font-size: medium;
  font-weight: bold;
  text-align: center;
  display: block;
  margin: inherit;
}

.no-spots-label {
  display: inline-flex;
  padding: 5px;
  flex-direction: row;
  justify-content: center;
}

.icon {
  margin-right: 10px;
  margin-top: 5px;
  color: #931a1a;
}

.submit-button {
  margin-top: 50px;
  background-color: #b8bbb9 !important;
  border-color: #b8bbb9 !important;
  font: #f7f2e8;
}

.obs-submit-button {
  margin-top: 50px;
  background-color: #931a1a !important;
  border-color: #931a1a !important;
  font: #f7f2e8;
}

.bfg-submit-button {
  margin-top: 50px;
  background-color: #444 !important;
  border-color: #444 !important;
  font: #f7f2e8;
}

.cig-submit-button {
  margin-top: 50px;
  background-color: #437f78 !important;
  border-color: #437f78 !important;
  font: #f7f2e8;
}

.spot-input {
  border-width: 0px;
  height: 50px;
  font-weight: bolder;
  font-size: xx-large;
  text-align: center;
  width: 200px;
  margin-top: 20px;
  letter-spacing: 5px;
  font-family: introRust, sans-serif;
  border: solid 1px #495057;
}

.obs-spot-input {
  background-color: #f7f2e8;
  border-width: 0px;
  height: 50px;
  font-weight: bolder;
  font-size: xx-large;
  text-align: center;
  width: 200px;
  margin-top: 20px;
  letter-spacing: 5px;
  font-family: introRust, sans-serif;
  border: solid 1px #495057;
}

.bfg-spot-input {
  background-color: #fff;
  border-width: 0px;
  height: 50px;
  font-weight: bolder;
  font-size: xx-large;
  text-align: center;
  width: 200px;
  margin-top: 20px;
  letter-spacing: 5px;
  font-family: introRust, sans-serif;
  border: solid 1px #495057;
}

.cig-spot-input {
  background-color: #fff;
  border-width: 0px;
  height: 50px;
  font-weight: bolder;
  font-size: xx-large;
  text-align: center;
  width: 200px;
  margin-top: 20px;
  letter-spacing: 5px;
  font-family: introRust, sans-serif;
  border: solid 1px #437f78;
}

.description {
  text-align: center;
  width: 300px;
  display: block;
  margin: 0 auto;
}

.title {
  padding: 5px;
  text-align: center !important;
  display: block;
  margin: 0 auto;
}

.error {
  color: #eb0c0c;
  display: block;
  text-align: center;
  margin: 0 auto;
  font-size: small;
}

.form-container {
  max-width: -webkit-fill-available;
}

.phone-link {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
}

.background {
  background: #fff !important;
}

.bfg-background {
  background: #fff !important;
}

.obs-background {
  background: #f7f2e8 !important;
}

.cig-background {
  background: #fff !important;
}

.news-banner {
  background-color: #6D2932;
  color: white;
  padding: 10px;
  text-align: center;
  font-size: 14px;
  margin-bottom: 20px;
}